<template>
  <div
    class="horizontal-layout"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >
    <b-navbar class="top-bar bg-primary">
      <b-container fluid class="mr-3 ml-3">
        <b-navbar-nav class="mr-auto d-none d-md-block d-sm-block">
          <b-nav-text class="text-white m-1">
            <a :href="'tel:+573118080225'" class="text-white">
              +57 3118080225
            </a>
          </b-nav-text>
          <b-nav-text class="text-white m-1">
            <a :href="'mailto:contacto@travabus.com.co'" class="text-white">
              contacto@travabus.com.co
            </a>
          </b-nav-text>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item link-classes="text-white" href="https://web.facebook.com/TravellBus?_rdc=1&_rdr#" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor">
              <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.35c0 .732.593 1.325 1.325 1.325h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.294h6.116c.732 0 1.325-.593 1.325-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/>
            </svg>
          </b-nav-item>
          <b-nav-item link-classes="text-white" href="https://www.instagram.com/travelbussas/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="white">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
              </svg>
            </b-nav-item>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    <b-navbar
      :toggleable="false"
      class="header-navbar align-items-center navbar-brand-center bg-primary d-xl-none d-xs-none"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
      >
        <b-navbar-brand class="navbar-header" style="margin: 0px auto;">
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <b-link
                class="navbar-brand"
                to="/"
              >
                <span class="brand-logo">
                  <b-img
                    :src="appLogoImage"
                    width="210"
                    alt="logo"
                    style="filter: brightness(0) invert(1);"
                  />
                </span>
              </b-link>
            </li>
          </ul>
        </b-navbar-brand>
      </slot>
    </b-navbar>

    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor: navbarType === 'static' && scrolledTo && skin === 'primary' ? 'primary' : null,
        boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
      }"
      :toggleable="false"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center white-color-white"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
      >
        <app-navbar-horizontal-layout-brand />
        <app-navbar-horizontal-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->

    <div class="horizontal-menu-wrapper">
      <div
        v-if="!isNavMenuHidden"
        class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-shadow menu-border d-none d-xl-block"
        :class="[navbarMenuTypeClass]"
      >
        <horizontal-nav-menu />
      </div>

      <!-- Vertical Nav Menu -->
      <vertical-nav-menu
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        class="d-block d-xl-none"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->
    </div>

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
      style="background: #152848"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>

    <slot name="customizer" />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar, BLink, BImg, BNavbarBrand, BNavbarNav, BContainer, BNavItem, BNavText } from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'

// Content Renderer
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import useLayoutHorizontal from './useLayoutHorizontal'
import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue'

// Vertical Menu
/* eslint-disable import/order */
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import mixinLayoutHorizontal from './mixinLayoutHorizontal'
import { $themeConfig } from '@themeConfig'

/* eslint-enable import/order */

export default {
  components: {
    AppBreadcrumb,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,

    BNavbar,
    BLink,
    BImg,
    BNavbarBrand,
    BNavbarNav,
    BContainer,
    BNavItem,
    BNavText,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,

    // Vertical Menu
    VerticalNavMenu,
  },
  mixins: [mixinLayoutHorizontal],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,

      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>

<style scoped>
.top-bar {
  background-color: #f8f9fa; /* Color de fondo de la barra superior */
  padding: 0.5rem 0;
}

.main-nav {
  background-color: #343a40; /* Color de fondo del menú de navegación */
}
</style>
